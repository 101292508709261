<template>
  <div
    v-if="metaData !== null"
    class="pagination-wrapper mt-3 mb-4">
    <p class="elements-counter">
      {{ metaData.from ? metaData.from : 0 }} - {{ metaData.to ? metaData.to : 0 }} from {{ metaData.total }} items
    </p>
    <div
      v-if="paginationButtons.length > 1"
      class="pagination">
      <f-button
        v-if="currentPage !== 1"
        theme="text"
        class="prev"
        @click.prevent.stop="goToPreviousPage()">
        Previous
      </f-button>

      <f-button
        v-for="(button, index) of paginationButtons"
        :key="'pagination-button-' + index"
        :disabled="button.disabled"
        :theme="button.label === currentPage ? 'primary' : 'outline'"
        :class="{ 'is-active': button.label === currentPage, 'page-number': true }"
        @click.prevent.stop="setPage(button.label)">
        {{ button.label }}
      </f-button>

      <f-button
        v-if="currentPage < metaData.last_page"
        theme="text"
        class="next"
        @click.prevent.stop="goToNextPage()">
        Next
      </f-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    metaData: {
      default: null,
      required: true,
      validator: prop => typeof prop === 'object' || prop === null
    }
  },
  computed: {
    paginationButtons () {
      let pagination = [];

      if (this.metaData.last_page <= 9) {
        for (let i = 1; i <= this.metaData.last_page; i++) {
          pagination.push({
            label: i,
            disabled: i === this.currentPage
          });
        }
      } else if (this.currentPage <= 3 || this.currentPage >= this.metaData.last_page - 2) {
        for (let i = 1; i <= 3; i++) {
          pagination.push({
            label: i,
            disabled: i === this.currentPage
          });
        }

        pagination.push({
          label: '...',
          disabled: true
        });

        for (let i = 2; i >= 0; i--) {
          pagination.push({
            label: this.metaData.last_page - i,
            disabled: this.metaData.last_page - i === this.currentPage
          });
        }
      } else {
        pagination.push({
          label: 1,
          disabled: false
        });

        pagination.push({
          label: 2,
          disabled: false
        });

        pagination.push({
          label: '...',
          disabled: true
        });

        for (let i = this.currentPage - 1; i <= this.currentPage + 1; i++) {
          pagination.push({
            label: i,
            disabled: i === this.currentPage
          });
        }

        pagination.push({
          label: '...',
          disabled: true
        });

        pagination.push({
          label: this.metaData.last_page - 1,
          disabled: false
        });

        pagination.push({
          label: this.metaData.last_page,
          disabled: false
        });
      }

      return pagination;
    }
  },
  data () {
    return {
      currentPage: 1
    };
  },
  mounted () {
    this.$bus.$on('pagination-reset', this.goToFirstPage);
  },
  methods: {
    setPage (page) {
      this.currentPage = page;
      this.triggerChanges();
    },
    goToFirstPage () {
      this.currentPage = 1;
      this.triggerChanges();
    },
    goToLastPage () {
      this.currentPage = this.metaData.last_page;
      this.triggerChanges();
    },
    goToNextPage () {
      this.currentPage++;

      if (this.currentPage > this.metaData.last_page) {
        this.currentPage = this.metaData.last_page;
      }

      this.triggerChanges();
    },
    goToPreviousPage () {
      this.currentPage--;

      if (this.currentPage < 1) {
        this.currentPage = 1;
      }

      this.triggerChanges();
    },
    triggerChanges () {
      this.$bus.$emit('pagination-page-changed', this.currentPage);
    },
  },
  beforeDestroy () {
    this.$bus.$off('pagination-reset', this.goToFirstPage);
  }
}
</script>
